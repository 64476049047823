import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Snackbar, { SnackbarOrigin } from "@material-ui/core/Snackbar";
import { makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import isEqual from "lodash.isequal";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import AppLayout from "../../components/AppLayout/index";
import ErrorDialog from "../../components/ErrorDialog";
import Loader from "../../components/Loader";
import { useUserService } from "../../services/user";

const useStyles = makeStyles((theme: Theme) => ({
    filledInfo: {
        backgroundColor: theme.palette.primary.main,
        marginTop: theme.spacing(8),
    },
}));

const Alert = (props: AlertProps) => {
    const classes = useStyles();

    return (
        <MuiAlert
            className={classes.filledInfo}
            elevation={0}
            variant="filled"
            {...props}
        />
    );
};

interface User {
    firstName: string;
    lastName: string;
    jobTitle: string;
}

interface SnackbarState extends SnackbarOrigin {
    open: boolean;
}

const hasChanged = (fields: User, user: User | null) => {
    if (!user) {
        return true;
    }

    const { firstName, lastName, jobTitle } = user;
    return !isEqual(fields, { firstName, jobTitle, lastName });
};

const Profile = (): JSX.Element => {
    const { t } = useTranslation();
    const {
        user,
        update,
        loading,
        requestPasswordChange,
        logout,
        error,
        resetError,
    } = useUserService();
    const [busy, setBusy] = useState(false);
    const [firstName, setFirstName] = useState(user?.firstName || "");
    const [lastName, setLastName] = useState(user?.lastName || "");
    const [jobTitle, setJobTitle] = useState(user?.jobTitle || "");
    const [snackbarState, setSnackbarState] = useState<SnackbarState>({
        horizontal: "center",
        open: false,
        vertical: "top",
    });
    const changed = hasChanged({ firstName, jobTitle, lastName }, user);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (changed) {
            setBusy(true);
            await update(firstName, lastName, jobTitle);
            setBusy(false);
        }
    };

    const handlePasswordChange = async (
        e?: React.SyntheticEvent,
        reason?: string,
    ) => {
        e?.preventDefault();

        if (reason === "clickaway") {
            return;
        }

        try {
            await requestPasswordChange();
            logout();
            setSnackbarState({ ...snackbarState, open: false });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
        }
    };

    const openSnackbar = (newSnackbarState: SnackbarOrigin) => {
        setSnackbarState({ open: true, ...newSnackbarState });
    };

    return (
        <AppLayout currentPage={t("Profile")}>
            <Container maxWidth="sm">
                <Grid container spacing={2} direction="column">
                    <Loader loading={loading || snackbarState.open}>
                        <form
                            noValidate
                            autoComplete="off"
                            onSubmit={handleSubmit}
                        >
                            <Box p={2} width={1}>
                                <TextField
                                    id="firstName"
                                    label={t("FirstName")}
                                    value={firstName}
                                    onChange={event =>
                                        setFirstName(event.target.value)
                                    }
                                />
                            </Box>
                            <Box p={2} width={1}>
                                <TextField
                                    id="lastName"
                                    label={t("SurName")}
                                    value={lastName}
                                    onChange={event =>
                                        setLastName(event.target.value)
                                    }
                                />
                            </Box>
                            <Box p={2} width={1}>
                                <TextField
                                    id="jobTitle"
                                    label={t("Profession")}
                                    value={jobTitle}
                                    onChange={event =>
                                        setJobTitle(event.target.value)
                                    }
                                />
                            </Box>
                            <Box p={2} width={1}>
                                <Grid container direction="row" spacing={5}>
                                    <Grid item xs={6}>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            type="button"
                                            fullWidth
                                            onClick={() =>
                                                openSnackbar({
                                                    horizontal: "center",
                                                    vertical: "top",
                                                })
                                            }
                                        >
                                            {t("Adjust password")}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            type="submit"
                                            fullWidth
                                            disabled={!changed || busy}
                                        >
                                            {t("Save")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </form>
                    </Loader>
                </Grid>
                <Snackbar
                    anchorOrigin={{
                        horizontal: snackbarState.horizontal,
                        vertical: snackbarState.vertical,
                    }}
                    open={snackbarState.open}
                    onClose={handlePasswordChange}
                    key={snackbarState.vertical + snackbarState.horizontal}
                >
                    <Alert severity="info" onClose={handlePasswordChange}>
                        {t("passwordChange")}
                    </Alert>
                </Snackbar>
            </Container>
            <ErrorDialog error={error} reset={resetError} />
        </AppLayout>
    );
};

export default Profile;
